import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { emptyDate, pixelID, googleConversionActionID, yandexConversionActionId, googleAdsID, googleAnalyticsID } from '../config/constants';
import { addDays, startOfDay } from 'date-fns';

export const timeFormat = (startTime: number, endTime: number) => `${format(addMinutes(emptyDate, startTime), 'HH-mm')} - ${format(
  addMinutes(emptyDate, endTime),
  'HH-mm'
)}`;

export const timeFormatFromTime = (time: number) => formatDate(addMinutes(emptyDate, time));

export const formatDate = (date: Date): string => {
  const hours24 = date.getHours();
  const hours12 = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const ampm = hours24 < 12 ? 'am' : 'pm';

  const hours12String = hours12.toString().padStart(2, '0');
  const minutesString = minutes.toString().padStart(2, '0');

  return `${hours12String}:${minutesString} ${ampm}`;
};

export const declOfNum = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
};

export function getGoogleAdsCode() {
  if (!googleAdsID) {
    return null;
  }
  return getGoogleTrackingCode(googleAdsID);
}

export function getGoogleAnalyticsCode() {
  if (!googleAnalyticsID) {
    return null;
  }
  return getGoogleTrackingCode(googleAnalyticsID);
}

function getGoogleTrackingCode(googleTag: string) {
  return `window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${googleTag}');`
}

export function getFBTrackingCode() {
  if (!pixelID) {
    return null;
  }
  return `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${pixelID}');
            fbq('track', 'PageView');`;
  }
  
export function getNoScriptFBTrackingCode() {
  if (!pixelID) {
    return null;
  }
  return `<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=${pixelID}&ev=PageView&noscript=1"
          />`;
}

export function googleConversion(conversionValue?: string, bookingId?: string) {
  if (!googleAdsID || !googleConversionActionID) {
    return null;
  }
  return `window.gtag('event', 'conversion', {
                        'send_to': '${googleConversionActionID}',
                        'value': ${conversionValue},
                        'currency': 'USD',
                        'transaction_id': '${bookingId}'
                      })`;
}

export function yandexConversion(conversionValue?: string) {
  if (!yandexConversionActionId) {
    return null;
  }
  return `ym(${yandexConversionActionId}, 'reachGoal','Purchase', {order_price: '${conversionValue}', currency: 'USD'});`
}

export function fbConversion(conversionValue?: string) {
  if (!pixelID) {
    return null;
  }
  return `fbq('track', 'Purchase', {
              value: ${conversionValue},
              currency: 'USD',
            });`;
}

export const getDates = (startDate: Date, stopDate: Date, limit?: number): Date[] => {
  const dateArray = [];
  let currentDate = startDate;
  while (startOfDay(currentDate) <= startOfDay(stopDate) && (!limit || limit > dateArray.length)) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};